<template>
  <div>
    &nbsp;&nbsp;
  </div>
</template>

<script>
export default {
  name: "Tournament",
  created() {
    window.location.href = "https://hullor.io";
  }
};
</script>

<style scoped></style>
