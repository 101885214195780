<template>
  <div class="modal-background" style="display:block;">
    <div class="modal-content pop-02">
      <span class="close" @click="hideModal()"></span>
      <h4>Are you sure to deactivate account ?</h4>
      <button type="submit" class="submit-btn5" @click="deactivateProfile()">
        Okay
      </button>
      <button type="submit" class="submit-btn6" @click="hideModal('')">
        Cancel
      </button>
      <div
        style="background-color: red; margin-top: 20px;padding: 7px;"
        v-if="errorMsg !== ''"
      >
        {{ errorMsg }}
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Unsubscribe",
  data: () => {
    return {
      host: null,
      errorMsg: ""
    };
  },
  created() {
    this.host = this.$root.url();
    const phone = localStorage.getItem("phone")
      ? localStorage.getItem("phone")
      : "";
    if (phone === "") return this.$router.push({ name: "Registration" });
  },
  methods: {
    async deactivateProfile() {
      try {
        //let vm = this;
        $(".submit-btn5").hide();
        const msisdn = localStorage.getItem("phone")
          ? localStorage.getItem("phone")
          : "";
        if (msisdn === "") {
          return this.$router.push({ name: "Registration" });
        }
        let curTime = Date.now();
        let randomNumber = Math.floor(100000000 + Math.random() * 900000000);
        let individualTransactionId = curTime + msisdn + randomNumber;
        const path = "deactivateProfile";
        const url = `${
          this.host
        }/${path}?msisdn=${msisdn}&operator=${2}&transactionId=${individualTransactionId}`;
        let response = await axios.get(url);
        let { success, unsubscriptionRedirectUrl, code } = response.data;
        if (success) {
          //if (operator == 2) {
          window.location.href = unsubscriptionRedirectUrl;
          // } else {
          //   this.errorMsg = "THIS FUNCTION ONLY APPLICABLE FOR GP";
          //   setTimeout(function () {
          //     vm.$router.push({ name: "Home"});
          //   },2000);
          // }
          return true;
        } else {
          if (code === 3004) {
            localStorage.clear();
            this.$router.push({ name: "Registration" });
            return true;
          }
          $(".submit-btn5").show();
          this.errorMsg = "Something wrong, please try again later";
        }
      } catch (e) {
        $(".submit-btn5").show();
        this.errorMsg = "Something wrong, please try again later";
      }
    },
    hideModal() {
      return this.$router.push({ name: "Home" });
    }
  }
};
</script>

<style scoped></style>
